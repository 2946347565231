import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './book.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BookDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const bookEntity = useAppSelector(state => state.book.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bookDetailsHeading">
          <Translate contentKey="libraryApp.book.detail.title">Book</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{bookEntity.id}</dd>
          <dt>
            <span id="productId">
              <Translate contentKey="libraryApp.book.productId">Product Id</Translate>
            </span>
          </dt>
          <dd>{bookEntity.productId}</dd>
          <dt>
            <span id="bookId">
              <Translate contentKey="libraryApp.book.bookId">Book Id</Translate>
            </span>
          </dt>
          <dd>{bookEntity.bookId}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="libraryApp.book.name">Name</Translate>
            </span>
          </dt>
          <dd>{bookEntity.name}</dd>
          <dt>
            <span id="isbn">
              <Translate contentKey="libraryApp.book.isbn">Isbn</Translate>
            </span>
          </dt>
          <dd>{bookEntity.isbn}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="libraryApp.book.description">Description</Translate>
            </span>
          </dt>
          <dd>{bookEntity.description}</dd>
          <dt>
            <span id="barcode">
              <Translate contentKey="libraryApp.book.barcode">Barcode</Translate>
            </span>
          </dt>
          <dd>{bookEntity.barcode}</dd>
          <dt>
            <span id="articleCode">
              <Translate contentKey="libraryApp.book.articleCode">Article Code</Translate>
            </span>
          </dt>
          <dd>{bookEntity.articleCode}</dd>
          <dt>
            <span id="authorCode">
              <Translate contentKey="libraryApp.book.authorCode">Author Code</Translate>
            </span>
          </dt>
          <dd>{bookEntity.authorCode}</dd>
          <dt>
            <span id="authorName">
              <Translate contentKey="libraryApp.book.authorName">Author Name</Translate>
            </span>
          </dt>
          <dd>{bookEntity.authorName}</dd>
          <dt>
            <span id="publisherCode">
              <Translate contentKey="libraryApp.book.publisherCode">Publisher Code</Translate>
            </span>
          </dt>
          <dd>{bookEntity.publisherCode}</dd>
          <dt>
            <span id="publisherName">
              <Translate contentKey="libraryApp.book.publisherName">Publisher Name</Translate>
            </span>
          </dt>
          <dd>{bookEntity.publisherName}</dd>
          <dt>
            <span id="categoryCode">
              <Translate contentKey="libraryApp.book.categoryCode">Category Code</Translate>
            </span>
          </dt>
          <dd>{bookEntity.categoryCode}</dd>
          <dt>
            <span id="categoryName">
              <Translate contentKey="libraryApp.book.categoryName">Category Name</Translate>
            </span>
          </dt>
          <dd>{bookEntity.categoryName}</dd>
          <dt>
            <span id="categoryL1Code">
              <Translate contentKey="libraryApp.book.categoryL1Code">Category L 1 Code</Translate>
            </span>
          </dt>
          <dd>{bookEntity.categoryL1Code}</dd>
          <dt>
            <span id="categoryL1Name">
              <Translate contentKey="libraryApp.book.categoryL1Name">Category L 1 Name</Translate>
            </span>
          </dt>
          <dd>{bookEntity.categoryL1Name}</dd>
          <dt>
            <span id="categoryL2Code">
              <Translate contentKey="libraryApp.book.categoryL2Code">Category L 2 Code</Translate>
            </span>
          </dt>
          <dd>{bookEntity.categoryL2Code}</dd>
          <dt>
            <span id="categoryL2Name">
              <Translate contentKey="libraryApp.book.categoryL2Name">Category L 2 Name</Translate>
            </span>
          </dt>
          <dd>{bookEntity.categoryL2Name}</dd>
          <dt>
            <span id="category">
              <Translate contentKey="libraryApp.book.category">Category</Translate>
            </span>
          </dt>
          <dd>{bookEntity.category}</dd>
          <dt>
            <span id="vendorCode">
              <Translate contentKey="libraryApp.book.vendorCode">Vendor Code</Translate>
            </span>
          </dt>
          <dd>{bookEntity.vendorCode}</dd>
          <dt>
            <span id="thumbnailUrl">
              <Translate contentKey="libraryApp.book.thumbnailUrl">Thumbnail Url</Translate>
            </span>
          </dt>
          <dd>{bookEntity.thumbnailUrl}</dd>
          <dt>
            <span id="noOfPages">
              <Translate contentKey="libraryApp.book.noOfPages">No Of Pages</Translate>
            </span>
          </dt>
          <dd>{bookEntity.noOfPages}</dd>
          <dt>
            <span id="priceFull">
              <Translate contentKey="libraryApp.book.priceFull">Price Full</Translate>
            </span>
          </dt>
          <dd>{bookEntity.priceFull}</dd>
          <dt>
            <span id="priceDiscount">
              <Translate contentKey="libraryApp.book.priceDiscount">Price Discount</Translate>
            </span>
          </dt>
          <dd>{bookEntity.priceDiscount}</dd>
          <dt>
            <span id="priceDollar">
              <Translate contentKey="libraryApp.book.priceDollar">Price Dollar</Translate>
            </span>
          </dt>
          <dd>{bookEntity.priceDollar}</dd>
          <dt>
            <span id="hasPdfFile">
              <Translate contentKey="libraryApp.book.hasPdfFile">Has Pdf File</Translate>
            </span>
          </dt>
          <dd>{bookEntity.hasPdfFile ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasEpubFile">
              <Translate contentKey="libraryApp.book.hasEpubFile">Has Epub File</Translate>
            </span>
          </dt>
          <dd>{bookEntity.hasEpubFile ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasSamplePdfFile">
              <Translate contentKey="libraryApp.book.hasSamplePdfFile">Has Sample Pdf File</Translate>
            </span>
          </dt>
          <dd>{bookEntity.hasSamplePdfFile ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasSampleEpubFile">
              <Translate contentKey="libraryApp.book.hasSampleEpubFile">Has Sample Epub File</Translate>
            </span>
          </dt>
          <dd>{bookEntity.hasSampleEpubFile ? 'true' : 'false'}</dd>
          <dt>
            <span id="key">
              <Translate contentKey="libraryApp.book.key">Key</Translate>
            </span>
          </dt>
          <dd>{bookEntity.key}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="libraryApp.book.status">Status</Translate>
            </span>
          </dt>
          <dd>{bookEntity.status}</dd>
        </dl>
        <Button tag={Link} to="/book" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/book/${bookEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BookDetail;
