import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, updateEntity, createEntity, reset } from './book.reducer';
import { IBook } from 'app/shared/model/book.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { BookStatusEnum } from 'app/shared/model/enumerations/book-status-enum.model';

export const BookUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const bookEntity = useAppSelector(state => state.book.entity);
  const loading = useAppSelector(state => state.book.loading);
  const updating = useAppSelector(state => state.book.updating);
  const updateSuccess = useAppSelector(state => state.book.updateSuccess);
  const bookStatusEnumValues = Object.keys(BookStatusEnum);
  const handleClose = () => {
    props.history.push('/book' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...bookEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          status: 'ACTIVE',
          ...bookEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="libraryApp.book.home.createOrEditLabel" data-cy="BookCreateUpdateHeading">
            <Translate contentKey="libraryApp.book.home.createOrEditLabel">Create or edit a Book</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="book-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('libraryApp.book.productId')}
                id="book-productId"
                name="productId"
                data-cy="productId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('libraryApp.book.bookId')}
                id="book-bookId"
                name="bookId"
                data-cy="bookId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField label={translate('libraryApp.book.name')} id="book-name" name="name" data-cy="name" type="text" />
              <ValidatedField label={translate('libraryApp.book.isbn')} id="book-isbn" name="isbn" data-cy="isbn" type="text" />
              <ValidatedField
                label={translate('libraryApp.book.description')}
                id="book-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField label={translate('libraryApp.book.barcode')} id="book-barcode" name="barcode" data-cy="barcode" type="text" />
              <ValidatedField
                label={translate('libraryApp.book.articleCode')}
                id="book-articleCode"
                name="articleCode"
                data-cy="articleCode"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.authorCode')}
                id="book-authorCode"
                name="authorCode"
                data-cy="authorCode"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.authorName')}
                id="book-authorName"
                name="authorName"
                data-cy="authorName"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.publisherCode')}
                id="book-publisherCode"
                name="publisherCode"
                data-cy="publisherCode"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.publisherName')}
                id="book-publisherName"
                name="publisherName"
                data-cy="publisherName"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.categoryCode')}
                id="book-categoryCode"
                name="categoryCode"
                data-cy="categoryCode"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.categoryName')}
                id="book-categoryName"
                name="categoryName"
                data-cy="categoryName"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.categoryL1Code')}
                id="book-categoryL1Code"
                name="categoryL1Code"
                data-cy="categoryL1Code"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.categoryL1Name')}
                id="book-categoryL1Name"
                name="categoryL1Name"
                data-cy="categoryL1Name"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.categoryL2Code')}
                id="book-categoryL2Code"
                name="categoryL2Code"
                data-cy="categoryL2Code"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.categoryL2Name')}
                id="book-categoryL2Name"
                name="categoryL2Name"
                data-cy="categoryL2Name"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.category')}
                id="book-category"
                name="category"
                data-cy="category"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.vendorCode')}
                id="book-vendorCode"
                name="vendorCode"
                data-cy="vendorCode"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.thumbnailUrl')}
                id="book-thumbnailUrl"
                name="thumbnailUrl"
                data-cy="thumbnailUrl"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.noOfPages')}
                id="book-noOfPages"
                name="noOfPages"
                data-cy="noOfPages"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.priceFull')}
                id="book-priceFull"
                name="priceFull"
                data-cy="priceFull"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.priceDiscount')}
                id="book-priceDiscount"
                name="priceDiscount"
                data-cy="priceDiscount"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.priceDollar')}
                id="book-priceDollar"
                name="priceDollar"
                data-cy="priceDollar"
                type="text"
              />
              <ValidatedField
                label={translate('libraryApp.book.hasPdfFile')}
                id="book-hasPdfFile"
                name="hasPdfFile"
                data-cy="hasPdfFile"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('libraryApp.book.hasEpubFile')}
                id="book-hasEpubFile"
                name="hasEpubFile"
                data-cy="hasEpubFile"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('libraryApp.book.hasSamplePdfFile')}
                id="book-hasSamplePdfFile"
                name="hasSamplePdfFile"
                data-cy="hasSamplePdfFile"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('libraryApp.book.hasSampleEpubFile')}
                id="book-hasSampleEpubFile"
                name="hasSampleEpubFile"
                data-cy="hasSampleEpubFile"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('libraryApp.book.key')}
                id="book-key"
                name="key"
                data-cy="key"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField label={translate('libraryApp.book.status')} id="book-status" name="status" data-cy="status" type="select">
                {bookStatusEnumValues.map(bookStatusEnum => (
                  <option value={bookStatusEnum} key={bookStatusEnum}>
                    {translate('libraryApp.BookStatusEnum.' + bookStatusEnum)}
                  </option>
                ))}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/book" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BookUpdate;
