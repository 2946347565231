import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, Progress, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { translate, getUrlParameter } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { handlePasswordResetFinish, reset } from '../password-reset.reducer';

export const PasswordResetFinishPage = (props: RouteComponentProps<{ key: string }>) => {
  const [form] = Form.useForm();
  const [password, setPassword] = useState('');
  const [key] = useState(getUrlParameter('key', props.location.search));
  const dispatch = useAppDispatch();

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  // Handle success message
  const successMessage = useAppSelector(state => state.passwordReset.successMessage);
  useEffect(() => {
    if (successMessage) {
      message.success(translate(successMessage));
    }
  }, [successMessage]);

  // Password strength calculation
  const calculatePasswordStrength = (pass: string): number => {
    let strength = 0;
    if (pass.length >= 8) strength += 25;
    if (pass.match(/[A-Z]/)) strength += 25;
    if (pass.match(/[0-9]/)) strength += 25;
    if (pass.match(/[!@#$%^&*]/)) strength += 25;
    return strength;
  };

  const handleSubmit = async (values: { newPassword: string }) => {
    await dispatch(handlePasswordResetFinish({ key, newPassword: values.newPassword }));
  };

  const getResetForm = () => (
    <Form form={form} onFinish={handleSubmit} layout="vertical" className="password-reset-form">
      <Form.Item
        name="newPassword"
        label={translate('global.form.newpassword.label')}
        rules={[
          { required: true, message: translate('global.messages.validate.newpassword.required') },
          { min: 6, message: 'Password must be at least 6 characters long.' },
          {
            pattern: /(?=.*[!@#$%^&*()_+\-={}|[\]:;"'<>,.?/])/,
            message: `Password must contain at least one special character (!@#$%^&*()_+|-={}[]:";'<>?,./)`,
          },
          { max: 50, message: translate('global.messages.validate.newpassword.maxlength') },
        ]}
      >
        <Input.Password
          placeholder={translate('global.form.newpassword.placeholder')}
          onChange={e => setPassword(e.target.value)}
          data-cy="resetPassword"
        />
      </Form.Item>

      {password && (
        <Progress
          percent={calculatePasswordStrength(password)}
          strokeColor={{
            '0%': '#ff0000',
            '50%': '#ffbf00',
            '100%': '#00ff00',
          }}
          status="active"
          className="mb-4"
        />
      )}

      <Form.Item
        name="confirmPassword"
        label={translate('global.form.confirmpassword.label')}
        dependencies={['newPassword']}
        rules={[
          { required: true, message: translate('global.messages.validate.confirmpassword.required') },
          { min: 6, message: 'Password must be at least 6 characters long.' },
          {
            pattern: /(?=.*[!@#$%^&*()_+\-={}|[\]:;"'<>,.?/])/,
            message: `Password must contain at least one special character (!@#$%^&*()_+|-={}[]:";'<>?,./)`,
          },
          { max: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(translate('global.messages.error.dontmatch')));
            },
          }),
        ]}
      >
        <Input.Password placeholder={translate('global.form.confirmpassword.placeholder')} data-cy="confirmResetPassword" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" data-cy="submit">
          {translate('reset.finish.form.button')}
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Row justify="center">
      <Col xs={24} sm={24} md={12} lg={8} xl={6}>
        <h1>{translate('reset.finish.title')}</h1>
        {key ? getResetForm() : null}
      </Col>
    </Row>
  );
};

export default PasswordResetFinishPage;
